import React from "react";
import { dictionary } from "../../conf/dictionary";

export default function TermsScreen() {
  return (
    <section style={styles.container}>
      <h1 style={styles.header}>Terms and Conditions</h1>
      <p style={styles.paragraph}>Last updated: August 30, 2024</p>
      <h3 style={styles.subSubHeader}>
        {dictionary("1. Acceptance of Terms", "esp")}
      </h3>
      <p style={styles.paragraph}>
        {dictionary(
          "By using Trainify's services, you agree to be bound by these Terms and Conditions. If you do not agree with these terms, you should not use our services.",
          "esp"
        )}
      </p>

      <h3 style={styles.subSubHeader}>
        {dictionary("2. Use of Our Services", "esp")}
      </h3>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          {dictionary(
            "2.1 Pay for Use: As a Trainify customer, you will be charged only for the services you utilize.",
            "esp"
          )}
        </li>
        <li style={styles.listItem}>
          {dictionary(
            "2.2 No Refunds: Upon making a purchase with Trainify, you acknowledge that all sales are final and there will be no refunds. Ensure you are confident in your purchase before finalizing.",
            "esp"
          )}
        </li>
      </ul>

      <h3 style={styles.subSubHeader}>{dictionary("3. Proper Use", "esp")}</h3>
      <p style={styles.paragraph}>
        {dictionary(
          "You commit to using our services in a proper and respectful manner. Any misuse, including but not limited to, platform abuse, fraudulent use, tampering, or attempts to alter our systems, can result in the suspension or permanent banning of your access to our services.",
          "esp"
        )}
      </p>

      <h3 style={styles.subSubHeader}>
        {dictionary("4. Termination of Service", "esp")}
      </h3>
      <p style={styles.paragraph}>
        {dictionary(
          "Trainify reserves the right to suspend or terminate your access to our services if we believe your behavior breaches these terms or is harmful to other users, Trainify, or third parties, or for any other reason, at our sole discretion.",
          "esp"
        )}
      </p>

      <h3 style={styles.subSubHeader}>
        {dictionary("5. Limitation of Liability", "esp")}
      </h3>
      <p style={styles.paragraph}>
        {dictionary(
          "Trainify will not be liable for indirect, incidental, special, exemplary, or consequential damages, including lost profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of, or inability to access or use our services; (ii) any conduct or content of any third party on the services.",
          "esp"
        )}
      </p>

      <h3 style={styles.subSubHeader}>
        {dictionary("6. Modification of Terms", "esp")}
      </h3>
      <p style={styles.paragraph}>
        {dictionary(
          "We reserve the right to modify these Terms and Conditions at any time. It is your responsibility to regularly review these Terms and Conditions to be aware of any changes.",
          "esp"
        )}
      </p>

      <h3 style={styles.subSubHeader}>{dictionary("7. Contact", "esp")}</h3>
      <p style={styles.paragraph}>
        {dictionary(
          "For any questions related to these Terms and Conditions, please contact our support team.",
          "esp"
        )}
      </p>
    </section>
  );
}

const styles = {
  container: {
    padding: "40px",
    backgroundColor: "#222222",
    height: "100vh",
  },
  header: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "10px",
    marginTop: "20px",
    color: "#fff",
  },
  date: {
    fontSize: "14px",
    marginBottom: "10px",
    color: "#fff",
  },
  paragraph: {
    fontSize: "16px",
    marginBottom: "10px",
    color: "#fff",
  },
  subHeader: {
    fontSize: "20px",
    fontWeight: "bold",
    marginTop: "20px",
    marginBottom: "10px",
    color: "#fff",
  },
  subSubHeader: {
    fontSize: "18px",
    fontWeight: "bold",
    marginTop: "15px",
    marginBottom: "5px",
    color: "#fff",
  },
  h4: {
    fontSize: "16px",
    fontWeight: "bold",
    marginTop: "13px",
    marginBottom: "3px",
    color: "#fff",
  },
  list: {
    marginLeft: "10px",
    color: "#fff",
  },
  listItem: {
    fontSize: "16px",
    marginBottom: "5px",
    color: "#fff",
  },
  strong: {
    fontWeight: "bold",
    color: "#fff",
  },
  link: {
    color: "blue",
    textDecoration: "underline",
  },
};
