export default function Price({ hasBg }) {
  return (
    <>
      <div className="overflow-hidden">
        <section
          id="pricing"
          className={`package-section ${
            hasBg ? "gray-light-bg" : "background-shape-right"
          } ptb-100`}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center mb-5">
                  <h2>Elige el plan perfecto para alcanzar tus metas</h2>
                  <p className="lead">
                    Flexibilidad en tus entrenamientos: escoge el plan que mejor
                    se adapte a ti y empieza a mejorar tu condición física hoy
                    mismo
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md">
                <div className="card text-center single-pricing-pack p-5">
                  <h5 className="mb-2">Plan de 1 mes</h5>
                  <div className="pricing-img mt-3 mb-4">
                    <img
                      src="assets/img/1-month.png"
                      alt="pricing img"
                      className="img-fluid"
                    />
                  </div>

                  <div className="card-body p-0">
                    <p>
                      Ideal si quieres probar la app y comenzar a ver resultados
                      en tu entrenamiento. Perfecto para quienes buscan
                      flexibilidad y un plan ajustado a corto plazo.
                    </p>
                    <ul className="list-unstyled text-sm pricing-feature-list">
                      <li>Acceso completo a todas las funciones.</li>
                      <li>Plan de entrenamiento mensual personalizado</li>
                      <li>Evaluación física para ajustar tu rutina.</li>
                      <li>Soporte 24/7 por chat y email.</li>
                    </ul>
                    <div className="py-4 border-0 pricing-header">
                      <div className="h1 text-center mb-0 color-secondary">
                        $<span className="price font-weight-bolder">59</span>
                      </div>
                    </div>
                    <a
                      href="#/"
                      className="btn outline-btn"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Empieza ahora
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md">
                <div className="card text-center popular-price single-pricing-pack p-5">
                  <h5 className="mb-2">Plan de 3 meses</h5>
                  <div className="pricing-img mt-3 mb-4">
                    <img
                      src="assets/img/3-months.png"
                      alt="pricing img"
                      className="img-fluid"
                    />
                  </div>

                  <div className="card-body p-0">
                    <p>
                      Un plan diseñado para quienes buscan comprometerse más
                      tiempo y lograr cambios consistentes en su entrenamiento.
                      Ahorra más y sigue subiendo de nivel.
                    </p>
                    <ul className="list-unstyled text-sm pricing-feature-list">
                      <li>Acceso completo a todas las funciones.</li>
                      <li>Plan de entrenamiento mensual personalizado</li>
                      <li>Evaluación física para ajustar tu rutina.</li>
                      <li>Soporte 24/7 por chat y email.</li>
                    </ul>
                    <div className="py-4 border-0 pricing-header">
                      <div className="h1 text-center mb-0 color-secondary">
                        $<span className="price font-weight-bolder">169</span>
                      </div>
                    </div>
                    <a
                      href="#/"
                      className="btn solid-btn"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Empieza ahora
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md">
                <div className="card text-center single-pricing-pack p-5">
                  <h5 className="mb-2">Plan de 12 meses</h5>
                  <div className="pricing-img mt-3 mb-4">
                    <img
                      src="assets/img/12-months.png"
                      alt="pricing img"
                      className="img-fluid"
                    />
                  </div>

                  <div className="card-body p-0">
                    <p>
                      El plan definitivo para quienes están comprometidos con
                      sus metas a largo plazo. La mejor opción para maximizar tu
                      progreso y obtener los mejores resultados.
                    </p>
                    <ul className="list-unstyled text-sm pricing-feature-list">
                      <li>Acceso completo a todas las funciones.</li>
                      <li>Plan de entrenamiento mensual personalizado</li>
                      <li>Evaluación física para ajustar tu rutina.</li>
                      <li>Soporte 24/7 por chat y email.</li>
                    </ul>
                    <div className="py-4 border-0 pricing-header">
                      <div className="h1 text-center mb-0 color-secondary">
                        $<span className="price font-weight-bolder">599</span>
                      </div>
                    </div>
                    <a
                      href="#/"
                      className="btn outline-btn"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Empieza ahora
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="mt-5 text-center">
              <p className="mb-2">
                If you need custom services or Need more?
                <a href="#/" className="color-secondary">
                  {" "}
                  Contact us{" "}
                </a>
              </p>
            </div> */}
          </div>
        </section>
      </div>
    </>
  );
}
