import React from "react";

export default function PrivacyPolicyScreen() {
  return (
    <section style={styles.container}>
      <h1 style={styles.header}>Privacy Policy</h1>
      <p style={styles.paragraph}>Last updated: August 30, 2024</p>
      <p style={styles.paragraph}>
        This Privacy Policy describes Our policies and procedures on the
        collection, use and disclosure of Your information when You use the
        Service and tells You about Your privacy rights and how the law protects
        You.
      </p>
      <p style={styles.paragraph}>
        We use Your Personal data to provide and improve the Service. By using
        the Service, You agree to the collection and use of information in
        accordance with this Privacy Policy.
      </p>
      <h2 style={styles.subHeader}>Interpretation and Definitions</h2>
      <h3 style={styles.subSubHeader}>Interpretation</h3>
      <p style={styles.paragraph}>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </p>
      <h3 style={styles.subSubHeader}>Definitions</h3>
      <p style={styles.paragraph}>For the purposes of this Privacy Policy:</p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>Account</strong> means a unique
            account created for You to access our Service or parts of our
            Service.
          </p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>Affiliate</strong> means an entity
            that controls, is controlled by or is under common control with a
            party, where &quot;control&quot; means ownership of 50% or more of
            the shares, equity interest or other securities entitled to vote for
            election of directors or other managing authority.
          </p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>Application</strong> refers to
            Trainify, the software program provided by the Company.
          </p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>Business</strong>, for the purpose of
            CCPA/CPRA, refers to the Company as the legal entity that collects
            Consumers' personal information and determines the purposes and
            means of the processing of Consumers' personal information, or on
            behalf of which such information is collected and that alone, or
            jointly with others, determines the purposes and means of the
            processing of consumers' personal information, that does business in
            the State of California.
          </p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>CCPA</strong> and/or{" "}
            <strong style={styles.strong}>CPRA</strong> refers to the California
            Consumer Privacy Act (the &quot;CCPA&quot;) as amended by the
            California Privacy Rights Act of 2020 (the &quot;CPRA&quot;).
          </p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>Company</strong> (referred to as
            either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or
            &quot;Our&quot; in this Agreement) refers to Trainify.
          </p>
          <p style={styles.paragraph}>
            For the purpose of the GDPR, the Company is the Data Controller.
          </p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>Consumer</strong>, for the purpose of
            the CCPA/CPRA, means a natural person who is a California resident.
            A resident, as defined in the law, includes (1) every individual who
            is in the USA for other than a temporary or transitory purpose, and
            (2) every individual who is domiciled in the USA who is outside the
            USA for a temporary or transitory purpose.
          </p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>Country</strong> refers to: Mexico
          </p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>Data Controller</strong>, for the
            purposes of the GDPR (General Data Protection Regulation), refers to
            the Company as the legal person which alone or jointly with others
            determines the purposes and means of the processing of Personal
            Data.
          </p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>Device</strong> means any device that
            can access the Service such as a computer, a cellphone or a digital
            tablet.
          </p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>Do Not Track</strong> (DNT) is a
            concept that has been promoted by US regulatory authorities, in
            particular the U.S. Federal Trade Commission (FTC), for the Internet
            industry to develop and implement a mechanism for allowing internet
            users to control the tracking of their online activities across
            websites.
          </p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>Facebook Fan Page</strong> is a public
            profile named Trainify.mx specifically created by the Company on the
            Facebook social network, accessible from{" "}
            <a
              style={styles.link}
              href="https://www.facebook.com/trainify.mx"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              https://www.facebook.com/trainify.mx
            </a>
          </p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>GDPR</strong> refers to EU General
            Data Protection Regulation.
          </p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>Personal Data</strong> is any
            information that relates to an identified or identifiable
            individual.
          </p>
          <p style={styles.paragraph}>
            For the purposes of GDPR, Personal Data means any information
            relating to You such as a name, an identification number, location
            data, online identifier or to one or more factors specific to the
            physical, physiological, genetic, mental, economic, cultural or
            social identity.
          </p>
          <p style={styles.paragraph}>
            For the purposes of the CCPA/CPRA, Personal Data means any
            information that identifies, relates to, describes or is capable of
            being associated with, or could reasonably be linked, directly or
            indirectly, with You.
          </p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>Service</strong> refers to the
            Application.
          </p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>Service Provider</strong> means any
            natural or legal person who processes the data on behalf of the
            Company. It refers to third-party companies or individuals employed
            by the Company to facilitate the Service, to provide the Service on
            behalf of the Company, to perform services related to the Service or
            to assist the Company in analyzing how the Service is used. For the
            purpose of the GDPR, Service Providers are considered Data
            Processors.
          </p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>
              Third-party Social Media Service
            </strong>{" "}
            refers to any website or any social network website through which a
            User can log in or create an account to use the Service.
          </p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>Usage Data</strong> refers to data
            collected automatically, either generated by the use of the Service
            or from the Service infrastructure itself (for example, the duration
            of a page visit).
          </p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>You</strong> means the individual
            accessing or using the Service, or the company, or other legal
            entity on behalf of which such individual is accessing or using the
            Service, as applicable.
          </p>
          <p style={styles.paragraph}>
            Under GDPR, You can be referred to as the Data Subject or as the
            User as you are the individual using the Service.
          </p>
        </li>
      </ul>
      <h2 style={styles.subHeader}>Collecting and Using Your Personal Data</h2>
      <h3 style={styles.subSubHeader}>Types of Data Collected</h3>
      <h4 style={styles.h4}>Personal Data</h4>
      <p style={styles.paragraph}>
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can be used to contact or
        identify You. Personally identifiable information may include, but is
        not limited to:
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>Email address</p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>First name</p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>Usage Data</p>
        </li>
      </ul>
      <h4 style={styles.h4}>Usage Data</h4>
      <p style={styles.paragraph}>
        Usage Data is collected automatically when using the Service.
      </p>
      <p style={styles.paragraph}>
        Usage Data may include information such as Your Device's Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that You visit, the time and date of Your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data.
      </p>
      <p style={styles.paragraph}>
        When You access the Service by or through a mobile device, We may
        collect certain information automatically, including, but not limited
        to, the type of mobile device You use, Your mobile device unique ID, the
        IP address of Your mobile device, Your mobile operating system, the type
        of mobile Internet browser You use, unique device identifiers and other
        diagnostic data.
      </p>
      <p style={styles.paragraph}>
        We may also collect information that Your browser sends whenever You
        visit our Service or when You access the Service by or through a mobile
        device.
      </p>
      <h4 style={styles.h4}>
        Information from Third-Party Social Media Services
      </h4>
      <p style={styles.paragraph}>
        The Company allows You to create an account and log in to use the
        Service through the following Third-party Social Media Services:
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>Google</li>
        <li style={styles.listItem}>Facebook</li>
        <li style={styles.listItem}>Instagram</li>
        <li style={styles.listItem}>TikTok</li>
        <li style={styles.listItem}>X (Twitter)</li>
        <li style={styles.listItem}>Linkedin</li>
        <li style={styles.listItem}>Telegram</li>
      </ul>
      <p style={styles.paragraph}>
        If You decide to register through or otherwise grant us access to a
        Third-Party Social Media Service, We may collect Personal data that is
        already associated with Your Third-Party Social Media Service's account,
        such as Your name, Your email address, Your activities or Your contact
        list associated with that account.
      </p>
      <p style={styles.paragraph}>
        You may also have the option of sharing additional information with the
        Company through Your Third-Party Social Media Service's account. If You
        choose to provide such information and Personal Data, during
        registration or otherwise, You are giving the Company permission to use,
        share, and store it in a manner consistent with this Privacy Policy.
      </p>
      <h4 style={styles.h4}>Use of Sensitive and Health Data</h4>
      <p style={styles.paragraph}>
        In addition to basic information such as name and email, Our app
        collects data related to health and physical condition, including:
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            Physical condition (beginner, intermediate, advanced)
          </p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>User gender</p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>Birthdate</p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>Injuries</p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>Type of muscle fibers</p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>Training days per week</p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            Heart rate at rest and during physical tests
          </p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>Weight</p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>Strength index</p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            Somatotype (ectomorph, mesomorph, endomorph)
          </p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>Flexibility level</p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>Vo2 max</p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            Health questionnaire with questions related to medical history,
            lifestyle, alcohol consumption, smoking, etc.
          </p>
        </li>
      </ul>
      <h4 style={styles.h4}>
        Information Collected while Using the Application
      </h4>
      <p style={styles.paragraph}>
        While using Our Application, in order to provide features of Our
        Application, We may collect, with Your prior permission:
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          Pictures and other information from your Device's camera and photo
          library
        </li>
      </ul>
      <p style={styles.paragraph}>
        We use this information to provide features of Our Service, to improve
        and customize Our Service. The information may be uploaded to the
        Company's servers and/or a Service Provider's server or it may be simply
        stored on Your device.
      </p>
      <p style={styles.paragraph}>
        You can enable or disable access to this information at any time,
        through Your Device settings.
      </p>
      <h2 style={styles.subSubHeader}>Use of Your Personal Data</h2>
      <p style={styles.paragraph}>
        The Company may use Personal Data for the following purposes:
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          To provide and maintain our Service, including to monitor the usage of
          our Service.
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>
            Use of Sensitive and Health Data:
          </strong>{" "}
          The collection of data related to health and physical condition allows
          us to personalize training plans according to the user's specific
          needs. This data is used to:
          <ul>
            <li style={styles.listItem}>
              Calculate trainable heart rate and Vo2 max using the Karvonen
              method.
            </li>
            <li style={styles.listItem}>
              Adapt exercise routines according to the user's somatotype and
              type of muscle fibers.
            </li>
            <li style={styles.listItem}>
              Ensure that recommended routines do not include exercises that may
              aggravate reported injuries.
            </li>
            <li style={styles.listItem}>
              Monitor physical progress and adjust training plans according to
              the user's evolution.
            </li>
          </ul>
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>To manage Your Account:</strong> to
          manage Your registration as a user of the Service. The Personal Data
          You provide can give You access to different functionalities of the
          Service that are available to You as a registered user.
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>
            For the performance of a contract:
          </strong>{" "}
          the development, compliance and undertaking of the purchase contract
          for the products, items or services You have purchased or of any other
          contract with Us through the Service.
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>To contact You:</strong> To contact You
          by email, telephone calls, SMS, or other equivalent forms of
          electronic communication, such as a mobile application's push
          notifications regarding updates or informative communications related
          to the functionalities, products or contracted services, including the
          security updates, when necessary or reasonable for their
          implementation.
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>To provide You</strong> with news,
          special offers, and general information about other goods, services,
          and events which we offer that are similar to those that you have
          already purchased or enquired about unless You have opted not to
          receive such information.
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>To manage Your requests:</strong> To
          attend and manage Your requests to Us.
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>
            To deliver targeted advertising to You:
          </strong>{" "}
          We may use Your information to develop and display content and
          advertising (and work with third-party vendors who do so) tailored to
          Your interests and/or location and to measure its effectiveness.
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>For business transfers:</strong> We may
          use Your information to evaluate or conduct a merger, divestiture,
          restructuring, reorganization, dissolution, or other sale or transfer
          of some or all of Our assets, whether as a going concern or as part of
          bankruptcy, liquidation, or similar proceeding, in which Personal Data
          held by Us about our Service users is among the assets transferred.
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>For other purposes:</strong> We may use
          Your information for other purposes, such as data analysis,
          identifying usage trends, determining the effectiveness of our
          promotional campaigns and to evaluate and improve our Service,
          products, services, marketing, and your experience.
        </li>
      </ul>
      <p style={styles.paragraph}>
        We may share Your personal information in the following situations:
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          <strong style={styles.strong}>With Service Providers:</strong> We may
          share Your personal information with Service Providers to monitor and
          analyze the use of our Service, to advertise on third-party websites
          to You after You visited our Service, for payment processing, to
          contact You.
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>For business transfers:</strong> We may
          share or transfer Your personal information in connection with, or
          during negotiations of, any merger, sale of Company assets, financing,
          or acquisition of all or a portion of Our business to another company.
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>With Affiliates:</strong> We may share
          Your information with Our affiliates, in which case we will require
          those affiliates to honor this Privacy Policy. Affiliates include Our
          parent company and any other subsidiaries, joint venture partners or
          other companies that We control or that are under common control with
          Us.
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>With business partners:</strong> We may
          share Your information with Our business partners to offer You certain
          products, services, or promotions.
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>With other users:</strong> when You
          share personal information or otherwise interact in the public areas
          with other users, such information may be viewed by all users and may
          be publicly distributed outside. If You interact with other users or
          register through a Third-Party Social Media Service, Your contacts on
          the Third-Party Social Media Service may see Your name, profile,
          pictures, and description of Your activity. Similarly, other users
          will be able to view descriptions of Your activity, communicate with
          You, and view Your profile.
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>With Your consent:</strong> We may
          disclose Your personal information for any other purpose with Your
          consent.
        </li>
      </ul>
      <h3 style={styles.subSubHeader}>Retention of Your Personal Data</h3>
      <p style={styles.paragraph}>
        The Company will retain Your Personal Data only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use Your Personal Data to the extent necessary to comply with
        our legal obligations (for example, if we are required to retain your
        data to comply with applicable laws), resolve disputes, and enforce our
        legal agreements and policies.
      </p>
      <p style={styles.paragraph}>
        We will retain sensitive data for as long as your account is active. If
        you decide to delete your account, all sensitive data associated with
        your profile will be permanently deleted, except in cases where it is
        necessary to retain certain information for legal reasons or to resolve
        disputes. In such cases, we will retain only the minimum data necessary
        to comply with our legal obligations.
      </p>
      <p style={styles.paragraph}>
        The Company will also retain Usage Data for internal analysis purposes.
        Usage Data is generally retained for a shorter period of time, except
        when this data is used to strengthen the security or to improve the
        functionality of Our Service, or We are legally obligated to retain this
        data for longer time periods.
      </p>
      <h2 style={styles.subSubHeader}>Transfer of Your Personal Data</h2>
      <p style={styles.paragraph}>
        Your information, including Personal Data, is processed at the Company's
        operating offices and in any other places where the parties involved in
        the processing are located. It means that this information may be
        transferred to — and maintained on — computers located outside of Your
        state, province, country or other governmental jurisdiction where the
        data protection laws may differ than those from Your jurisdiction.
      </p>
      <p style={styles.paragraph}>
        Your consent to this Privacy Policy followed by Your submission of such
        information represents Your agreement to that transfer.
      </p>
      <p style={styles.paragraph}>
        The Company will take all steps reasonably necessary to ensure that Your
        data is treated securely and in accordance with this Privacy Policy and
        no transfer of Your Personal Data will take place to an organization or
        a country unless there are adequate controls in place including the
        security of Your data and other personal information.
      </p>
      <h2 style={styles.subSubHeader}>Delete Your Personal Data</h2>
      <p style={styles.paragraph}>
        You have the right to delete or request that We assist in deleting the
        Personal Data that We have collected about You.
      </p>
      <p style={styles.paragraph}>
        Our Service may give You the ability to delete certain information about
        You from within the Service.
      </p>
      <p style={styles.paragraph}>
        You may update, amend, or delete Your information at any time by signing
        in to Your Account, if you have one, and visiting the account settings
        section that allows you to manage Your personal information. You may
        also contact Us to request access to, correct, or delete any personal
        information that You have provided to Us.
      </p>
      <p style={styles.paragraph}>
        Please note, however, that We may need to retain certain information
        when we have a legal obligation or lawful basis to do so.
      </p>
      <h2 style={styles.subSubHeader}>Disclosure of Your Personal Data</h2>
      <h4 style={styles.h4}>Business Transactions</h4>
      <p style={styles.paragraph}>
        If the Company is involved in a merger, acquisition or asset sale, Your
        Personal Data may be transferred. We will provide notice before Your
        Personal Data is transferred and becomes subject to a different Privacy
        Policy.
      </p>
      <h4 style={styles.h4}>Law enforcement</h4>
      <p style={styles.paragraph}>
        Under certain circumstances, the Company may be required to disclose
        Your Personal Data if required to do so by law or in response to valid
        requests by public authorities (e.g. a court or a government agency).
      </p>
      <h4 style={styles.h4}>Other legal requirements</h4>
      <p style={styles.paragraph}>
        The Company may disclose Your Personal Data in the good faith belief
        that such action is necessary to:
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>Comply with a legal obligation</li>
        <li style={styles.listItem}>
          Protect and defend the rights or property of the Company
        </li>
        <li style={styles.listItem}>
          Prevent or investigate possible wrongdoing in connection with the
          Service
        </li>
        <li style={styles.listItem}>
          Protect the personal safety of Users of the Service or the public
        </li>
        <li style={styles.listItem}>Protect against legal liability</li>
      </ul>
      <h3 style={styles.subSubHeader}>Security of Your Personal Data</h3>
      <p style={styles.paragraph}>
        The security of Your Personal Data is important to Us, but remember that
        no method of transmission over the Internet, or method of electronic
        storage is 100% secure. While We strive to use commercially acceptable
        means to protect Your Personal Data, We cannot guarantee its absolute
        security.
      </p>
      <li style={styles.listItem}>
        <strong style={styles.strong}>Security Measures</strong> We take the
        protection of personal and health data very seriously. Our application
        uses the following security measures:
      </li>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          Password encryption: Passwords are encrypted before being stored on
          our server.
        </li>
        <li style={styles.listItem}>
          Secure Authentication: We use JSON Web Tokens (JWT) to secure user
          sessions.
        </li>
        <li style={styles.listItem}>
          We restrict access to the database: Only our server instance has
          access to the database, even if the credentials are known.
        </li>
        <li style={styles.listItem}>
          Limited IP Access: Access to our EC2 instance is limited to a specific
          IP, preventing unauthorized access.
        </li>
        <li style={styles.listItem}>
          Input validation: Data sent through the frontend is validated to
          prevent attacks and vulnerabilities.
        </li>
      </ul>
      <h3 style={styles.subHeader}>
        Detailed Information on the Processing of Your Personal Data
      </h3>
      <p style={styles.paragraph}>
        The Service Providers We use may have access to Your Personal Data.
        These third-party vendors collect, store, use, process and transfer
        information about Your activity on Our Service in accordance with their
        Privacy Policies.
      </p>
      <h3 style={styles.subSubHeader}>Analytics</h3>
      <p style={styles.paragraph}>
        We may use third-party Service providers to monitor and analyze the use
        of our Service.
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>Google Analytics</strong>
          </p>
          <p style={styles.paragraph}>
            Google Analytics is a web analytics service offered by Google that
            tracks and reports website traffic. Google uses the data collected
            to track and monitor the use of our Service. This data is shared
            with other Google services. Google may use the collected data to
            contextualize and personalize the ads of its own advertising
            network.
          </p>
          <p style={styles.paragraph}>
            You may opt-out of certain Google Analytics features through your
            mobile device settings, such as your device advertising settings or
            by following the instructions provided by Google in their Privacy
            Policy:{" "}
            <a
              style={styles.link}
              href="https://policies.google.com/privacy"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              https://policies.google.com/privacy
            </a>
          </p>
          <p style={styles.paragraph}>
            For more information on the privacy practices of Google, please
            visit the Google Privacy &amp; Terms web page:{" "}
            <a
              style={styles.link}
              href="https://policies.google.com/privacy"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              https://policies.google.com/privacy
            </a>
          </p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>Firebase</strong>
          </p>
          <p style={styles.paragraph}>
            Firebase is an analytics service provided by Google Inc.
          </p>
          <p style={styles.paragraph}>
            You may opt-out of certain Firebase features through your mobile
            device settings, such as your device advertising settings or by
            following the instructions provided by Google in their Privacy
            Policy:{" "}
            <a
              style={styles.link}
              href="https://policies.google.com/privacy"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              https://policies.google.com/privacy
            </a>
          </p>
          <p style={styles.paragraph}>
            We also encourage you to review the Google's policy for safeguarding
            your data:{" "}
            <a
              style={styles.link}
              href="https://support.google.com/analytics/answer/6004245"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              https://support.google.com/analytics/answer/6004245
            </a>
          </p>
          <p style={styles.paragraph}>
            For more information on what type of information Firebase collects,
            please visit the How Google uses data when you use our partners'
            sites or apps webpage:{" "}
            <a
              style={styles.link}
              href="https://policies.google.com/technologies/partner-sites"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              https://policies.google.com/technologies/partner-sites
            </a>
          </p>
        </li>
      </ul>
      <h3 style={styles.subSubHeader}>Payments</h3>
      <p style={styles.paragraph}>
        We may provide paid products and/or services within the Service. In that
        case, we may use third-party services for payment processing (e.g.
        payment processors).
      </p>
      <p style={styles.paragraph}>
        We will not store or collect Your payment card details. That information
        is provided directly to Our third-party payment processors whose use of
        Your personal information is governed by their Privacy Policy. These
        payment processors adhere to the standards set by PCI-DSS as managed by
        the PCI Security Standards Council, which is a joint effort of brands
        like Visa, Mastercard, American Express and Discover. PCI-DSS
        requirements help ensure the secure handling of payment information.
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>Google Play In-App Payments</strong>
          </p>
          <p style={styles.paragraph}>
            Their Privacy Policy can be viewed at{" "}
            <a
              style={styles.link}
              href="https://www.google.com/policies/privacy/"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              https://www.google.com/policies/privacy/
            </a>
          </p>
        </li>
      </ul>
      <h3 style={styles.subSubHeader}>Behavioral Remarketing</h3>
      <p style={styles.paragraph}>
        The Company uses remarketing services to advertise to You after You
        accessed or visited our Service. We and Our third-party vendors use
        cookies and non-cookie technologies to help Us recognize Your Device and
        understand how You use our Service so that We can improve our Service to
        reflect Your interests and serve You advertisements that are likely to
        be of more interest to You.
      </p>
      <p style={styles.paragraph}>
        These third-party vendors collect, store, use, process and transfer
        information about Your activity on Our Service in accordance with their
        Privacy Policies and to enable Us to:
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          Measure and analyze traffic and browsing activity on Our Service
        </li>
        <li style={styles.listItem}>
          Show advertisements for our products and/or services to You on
          third-party websites or apps
        </li>
        <li style={styles.listItem}>
          Measure and analyze the performance of Our advertising campaigns
        </li>
      </ul>
      <p style={styles.paragraph}>
        Some of these third-party vendors may use non-cookie technologies that
        may not be impacted by browser settings that block cookies. Your browser
        may not permit You to block such technologies. You can use the following
        third-party tools to decline the collection and use of information for
        the purpose of serving You interest-based advertising:
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          The NAI's opt-out platform:{" "}
          <a
            style={styles.link}
            href="http://www.networkadvertising.org/choices/"
            rel="external nofollow noopener noreferrer"
            target="_blank"
          >
            http://www.networkadvertising.org/choices/
          </a>
        </li>
        <li style={styles.listItem}>
          The EDAA's opt-out platform{" "}
          <a
            style={styles.link}
            href="http://www.youronlinechoices.com/"
            rel="external nofollow noopener noreferrer"
            target="_blank"
          >
            http://www.youronlinechoices.com/
          </a>
        </li>
        <li style={styles.listItem}>
          The DAA's opt-out platform:{" "}
          <a
            style={styles.link}
            href="http://optout.aboutads.info/?c=2&amp;lang=EN"
            rel="external nofollow noopener noreferrer"
            target="_blank"
          >
            http://optout.aboutads.info/?c=2&amp;lang=EN
          </a>
        </li>
      </ul>
      <p style={styles.paragraph}>
        You may opt-out of all personalized advertising by enabling privacy
        features on Your mobile device such as Limit Ad Tracking (iOS) and Opt
        Out of Ads Personalization (Android). See Your mobile device Help system
        for more information.
      </p>
      <p style={styles.paragraph}>
        We may share information, such as hashed email addresses (if available)
        or other online identifiers collected on Our Service with these
        third-party vendors. This allows Our third-party vendors to recognize
        and deliver You ads across devices and browsers. To read more about the
        technologies used by these third-party vendors and their cross-device
        capabilities please refer to the Privacy Policy of each vendor listed
        below.
      </p>
      <p style={styles.paragraph}>The third-party vendors We use are:</p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>Google Ads (AdWords)</strong>
          </p>
          <p style={styles.paragraph}>
            Google Ads (AdWords) remarketing service is provided by Google Inc.
          </p>
          <p style={styles.paragraph}>
            You can opt-out of Google Analytics for Display Advertising and
            customize the Google Display Network ads by visiting the Google Ads
            Settings page:{" "}
            <a
              style={styles.link}
              href="http://www.google.com/settings/ads"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              http://www.google.com/settings/ads
            </a>
          </p>
          <p style={styles.paragraph}>
            Google also recommends installing the Google Analytics Opt-out
            Browser Add-on -{" "}
            <a
              style={styles.link}
              href="https://tools.google.com/dlpage/gaoptout"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              https://tools.google.com/dlpage/gaoptout
            </a>{" "}
            - for your web browser. Google Analytics Opt-out Browser Add-on
            provides visitors with the ability to prevent their data from being
            collected and used by Google Analytics.
          </p>
          <p style={styles.paragraph}>
            For more information on the privacy practices of Google, please
            visit the Google Privacy &amp; Terms web page:{" "}
            <a
              style={styles.link}
              href="https://policies.google.com/privacy"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              https://policies.google.com/privacy
            </a>
          </p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>Facebook</strong>
          </p>
          <p style={styles.paragraph}>
            Facebook remarketing service is provided by Facebook Inc.
          </p>
          <p style={styles.paragraph}>
            You can learn more about interest-based advertising from Facebook by
            visiting this page:{" "}
            <a
              style={styles.link}
              href="https://www.facebook.com/help/516147308587266"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              https://www.facebook.com/help/516147308587266
            </a>
          </p>
          <p style={styles.paragraph}>
            To opt-out from Facebook's interest-based ads, follow these
            instructions from Facebook:{" "}
            <a
              style={styles.link}
              href="https://www.facebook.com/help/568137493302217"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              https://www.facebook.com/help/568137493302217
            </a>
          </p>
          <p style={styles.paragraph}>
            Facebook adheres to the Self-Regulatory Principles for Online
            Behavioural Advertising established by the Digital Advertising
            Alliance. You can also opt-out from Facebook and other participating
            companies through the Digital Advertising Alliance in the USA{" "}
            <a
              style={styles.link}
              href="http://www.aboutads.info/choices/"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              http://www.aboutads.info/choices/
            </a>
            , the Digital Advertising Alliance of Canada in Canada{" "}
            <a
              style={styles.link}
              href="http://youradchoices.ca/"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              http://youradchoices.ca/
            </a>{" "}
            or the European Interactive Digital Advertising Alliance in Europe{" "}
            <a
              style={styles.link}
              href="http://www.youronlinechoices.eu/"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              http://www.youronlinechoices.eu/
            </a>
            , or opt-out using your mobile device settings.
          </p>
          <p style={styles.paragraph}>
            For more information on the privacy practices of Facebook, please
            visit Facebook's Data Policy:{" "}
            <a
              style={styles.link}
              href="https://www.facebook.com/privacy/explanation"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              https://www.facebook.com/privacy/explanation
            </a>
          </p>
        </li>
      </ul>
      <h3 style={styles.subSubHeader}>Usage, Performance and Miscellaneous</h3>
      <p style={styles.paragraph}>
        We may use third-party Service Providers to maintain and improve our
        Service.
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>FreshDesk</strong>
          </p>
          <p style={styles.paragraph}>
            FreshDesk is a customer support software. The service is operated by
            Freshworks, Inc.
          </p>
          <p style={styles.paragraph}>
            FreshDesk service may collect information from Your Device.
          </p>
          <p style={styles.paragraph}>
            The information gathered by FreshDesk is held in accordance with its
            Privacy Policy:{" "}
            <a
              style={styles.link}
              href="https://www.freshworks.com/privacy/"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              https://www.freshworks.com/privacy/
            </a>
          </p>
        </li>
      </ul>
      <h2 style={styles.subHeader}>GDPR Privacy</h2>
      <h3 style={styles.subSubHeader}>
        Legal Basis for Processing Personal Data under GDPR
      </h3>
      <p style={styles.paragraph}>
        We may process Personal Data under the following conditions:
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          <strong style={styles.strong}>Consent:</strong> You have given Your
          consent for processing Personal Data for one or more specific
          purposes.
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>Performance of a contract:</strong>{" "}
          Provision of Personal Data is necessary for the performance of an
          agreement with You and/or for any pre-contractual obligations thereof.
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>Legal obligations:</strong> Processing
          Personal Data is necessary for compliance with a legal obligation to
          which the Company is subject.
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>Vital interests:</strong> Processing
          Personal Data is necessary in order to protect Your vital interests or
          of another natural person.
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>Public interests:</strong> Processing
          Personal Data is related to a task that is carried out in the public
          interest or in the exercise of official authority vested in the
          Company.
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>Legitimate interests:</strong>{" "}
          Processing Personal Data is necessary for the purposes of the
          legitimate interests pursued by the Company.
        </li>
      </ul>
      <p style={styles.paragraph}>
        In any case, the Company will gladly help to clarify the specific legal
        basis that applies to the processing, and in particular whether the
        provision of Personal Data is a statutory or contractual requirement, or
        a requirement necessary to enter into a contract.
      </p>
      <h3 style={styles.subSubHeader}>Your Rights under the GDPR</h3>
      <p style={styles.paragraph}>
        The Company undertakes to respect the confidentiality of Your Personal
        Data and to guarantee You can exercise Your rights.
      </p>
      <p style={styles.paragraph}>
        You have the right under this Privacy Policy, and by law if You are
        within the EU, to:
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          <strong style={styles.strong}>
            Request access to Your Personal Data.
          </strong>{" "}
          The right to access, update or delete the information We have on You.
          Whenever made possible, you can access, update or request deletion of
          Your Personal Data directly within Your account settings section. If
          you are unable to perform these actions yourself, please contact Us to
          assist You. This also enables You to receive a copy of the Personal
          Data We hold about You.
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>
            Request correction of the Personal Data that We hold about You.
          </strong>{" "}
          You have the right to have any incomplete or inaccurate information We
          hold about You corrected.
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>
            Object to processing of Your Personal Data.
          </strong>{" "}
          This right exists where We are relying on a legitimate interest as the
          legal basis for Our processing and there is something about Your
          particular situation, which makes You want to object to our processing
          of Your Personal Data on this ground. You also have the right to
          object where We are processing Your Personal Data for direct marketing
          purposes.
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>
            Request erasure of Your Personal Data.
          </strong>{" "}
          You have the right to ask Us to delete or remove Personal Data when
          there is no good reason for Us to continue processing it.
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>
            Request the transfer of Your Personal Data.
          </strong>{" "}
          We will provide to You, or to a third-party You have chosen, Your
          Personal Data in a structured, commonly used, machine-readable format.
          Please note that this right only applies to automated information
          which You initially provided consent for Us to use or where We used
          the information to perform a contract with You.
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>Withdraw Your consent.</strong> You have
          the right to withdraw Your consent on using your Personal Data. If You
          withdraw Your consent, We may not be able to provide You with access
          to certain specific functionalities of the Service.
        </li>
      </ul>
      <h3 style={styles.subSubHeader}>
        Exercising of Your GDPR Data Protection Rights
      </h3>
      <p style={styles.paragraph}>
        You may exercise Your rights of access, rectification, cancellation and
        opposition by contacting Us. Please note that we may ask You to verify
        Your identity before responding to such requests. If You make a request,
        We will try our best to respond to You as soon as possible.
      </p>
      <p style={styles.paragraph}>
        You have the right to complain to a Data Protection Authority about Our
        collection and use of Your Personal Data. For more information, if You
        are in the European Economic Area (EEA), please contact Your local data
        protection authority in the EEA.
      </p>
      <h2 style={styles.subHeader}>Facebook Fan Page</h2>
      <h3 style={styles.subSubHeader}>
        Data Controller for the Facebook Fan Page
      </h3>
      <p style={styles.paragraph}>
        The Company is the Data Controller of Your Personal Data collected while
        using the Service. As operator of the Facebook Fan Page{" "}
        <a
          style={styles.link}
          href="https://www.facebook.com/trainify.mx"
          rel="external nofollow noopener noreferrer"
          target="_blank"
        >
          https://www.facebook.com/trainify.mx
        </a>
        , the Company and the operator of the social network Facebook are Joint
        Controllers.
      </p>
      <p style={styles.paragraph}>
        The Company has entered into agreements with Facebook that define the
        terms for use of the Facebook Fan Page, among other things. These terms
        are mostly based on the Facebook Terms of Service:{" "}
        <a
          style={styles.link}
          href="https://www.facebook.com/terms.php"
          rel="external nofollow noopener noreferrer"
          target="_blank"
        >
          https://www.facebook.com/terms.php
        </a>
      </p>
      <p style={styles.paragraph}>
        Visit the Facebook Privacy Policy{" "}
        <a
          style={styles.link}
          href="https://www.facebook.com/policy.php"
          rel="external nofollow noopener noreferrer"
          target="_blank"
        >
          https://www.facebook.com/policy.php
        </a>{" "}
        for more information about how Facebook manages Personal data or contact
        Facebook online, or by mail: Facebook, Inc. ATTN, Privacy Operations,
        1601 Willow Road, Menlo Park, CA 94025, United States.
      </p>
      <h3 style={styles.subSubHeader}>Facebook Insights</h3>
      <p style={styles.paragraph}>
        We use the Facebook Insights function in connection with the operation
        of the Facebook Fan Page and on the basis of the GDPR, in order to
        obtain anonymized statistical data about Our users.
      </p>
      <p style={styles.paragraph}>
        For this purpose, Facebook places a Cookie on the device of the user
        visiting Our Facebook Fan Page. Each Cookie contains a unique identifier
        code and remains active for a period of two years, except when it is
        deleted before the end of this period.
      </p>
      <p style={styles.paragraph}>
        Facebook receives, records and processes the information stored in the
        Cookie, especially when the user visits the Facebook services, services
        that are provided by other members of the Facebook Fan Page and services
        by other companies that use Facebook services.
      </p>
      <p style={styles.paragraph}>
        For more information on the privacy practices of Facebook, please visit
        Facebook Privacy Policy here:{" "}
        <a
          style={styles.link}
          href="https://www.facebook.com/privacy/explanation"
          rel="external nofollow noopener noreferrer"
          target="_blank"
        >
          https://www.facebook.com/privacy/explanation
        </a>
      </p>
      <h2 style={styles.subHeader}>
        CCPA/CPRA Privacy Notice (California Privacy Rights)
      </h2>
      <p style={styles.paragraph}>
        This privacy notice section for California residents supplements the
        information contained in Our Privacy Policy and it applies solely to all
        visitors, users, and others who reside in the State of California.
      </p>
      <h3 style={styles.subSubHeader}>
        Categories of Personal Information Collected
      </h3>
      <p style={styles.paragraph}>
        We collect information that identifies, relates to, describes,
        references, is capable of being associated with, or could reasonably be
        linked, directly or indirectly, with a particular Consumer or Device.
        The following is a list of categories of personal information which we
        may collect or may have been collected from California residents within
        the last twelve (12) months.
      </p>
      <p style={styles.paragraph}>
        Please note that the categories and examples provided in the list below
        are those defined in the CCPA/CPRA. This does not mean that all examples
        of that category of personal information were in fact collected by Us,
        but reflects our good faith belief to the best of Our knowledge that
        some of that information from the applicable category may be and may
        have been collected. For example, certain categories of personal
        information would only be collected if You provided such personal
        information directly to Us.
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>Category A: Identifiers.</strong>
          </p>
          <p style={styles.paragraph}>
            Examples: A real name, alias, postal address, unique personal
            identifier, online identifier, Internet Protocol address, email
            address, account name, driver's license number, passport number, or
            other similar identifiers.
          </p>
          <p style={styles.paragraph}>Collected: Yes.</p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>
              Category B: Personal information categories listed in the
              California Customer Records statute (Cal. Civ. Code § 1798.80(e)).
            </strong>
          </p>
          <p style={styles.paragraph}>
            Examples: A name, signature, Social Security number, physical
            characteristics or description, address, telephone number, passport
            number, driver's license or state identification card number,
            insurance policy number, education, employment, employment history,
            bank account number, credit card number, debit card number, or any
            other financial information, medical information, or health
            insurance information. Some personal information included in this
            category may overlap with other categories.
          </p>
          <p style={styles.paragraph}>Collected: Yes.</p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>
              Category C: Protected classification characteristics under
              California or federal law.
            </strong>
          </p>
          <p style={styles.paragraph}>
            Examples: Age (40 years or older), race, color, ancestry, national
            origin, citizenship, religion or creed, marital status, medical
            condition, physical or mental disability, sex (including gender,
            gender identity, gender expression, pregnancy or childbirth and
            related medical conditions), sexual orientation, veteran or military
            status, genetic information (including familial genetic
            information).
          </p>
          <p style={styles.paragraph}>Collected: No.</p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>
              Category D: Commercial information.
            </strong>
          </p>
          <p style={styles.paragraph}>
            Examples: Records and history of products or services purchased or
            considered.
          </p>
          <p style={styles.paragraph}>Collected: Yes.</p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>
              Category E: Biometric information.
            </strong>
          </p>
          <p style={styles.paragraph}>
            Examples: Genetic, physiological, behavioral, and biological
            characteristics, or activity patterns used to extract a template or
            other identifier or identifying information, such as, fingerprints,
            faceprints, and voiceprints, iris or retina scans, keystroke, gait,
            or other physical patterns, and sleep, health, or exercise data.
          </p>
          <p style={styles.paragraph}>Collected: No.</p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>
              Category F: Internet or other similar network activity.
            </strong>
          </p>
          <p style={styles.paragraph}>
            Examples: Interaction with our Service or advertisement.
          </p>
          <p style={styles.paragraph}>Collected: Yes.</p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>Category G: Geolocation data.</strong>
          </p>
          <p style={styles.paragraph}>
            Examples: Approximate physical location.
          </p>
          <p style={styles.paragraph}>Collected: No.</p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>Category H: Sensory data.</strong>
          </p>
          <p style={styles.paragraph}>
            Examples: Audio, electronic, visual, thermal, olfactory, or similar
            information.
          </p>
          <p style={styles.paragraph}>Collected: No.</p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>
              Category I: Professional or employment-related information.
            </strong>
          </p>
          <p style={styles.paragraph}>
            Examples: Current or past job history or performance evaluations.
          </p>
          <p style={styles.paragraph}>Collected: No.</p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>
              Category J: Non-public education information (per the Family
              Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34
              C.F.R. Part 99)).
            </strong>
          </p>
          <p style={styles.paragraph}>
            Examples: Education records directly related to a student maintained
            by an educational institution or party acting on its behalf, such as
            grades, transcripts, class lists, student schedules, student
            identification codes, student financial information, or student
            disciplinary records.
          </p>
          <p style={styles.paragraph}>Collected: No.</p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>
              Category K: Inferences drawn from other personal information.
            </strong>
          </p>
          <p style={styles.paragraph}>
            Examples: Profile reflecting a person's preferences,
            characteristics, psychological trends, predispositions, behavior,
            attitudes, intelligence, abilities, and aptitudes.
          </p>
          <p style={styles.paragraph}>Collected: No.</p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            <strong style={styles.strong}>
              Category L: Sensitive personal information.
            </strong>
          </p>
          <p style={styles.paragraph}>
            Examples: Account login and password information, geolocation data.
          </p>
          <p style={styles.paragraph}>Collected: Yes.</p>
        </li>
      </ul>
      <p style={styles.paragraph}>
        Under CCPA/CPRA, personal information does not include:
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          Publicly available information from government records
        </li>
        <li style={styles.listItem}>
          Deidentified or aggregated consumer information
        </li>
        <li style={styles.listItem}>
          Information excluded from the CCPA/CPRA's scope, such as:
          <ul style={styles.list}>
            <li style={styles.listItem}>
              Health or medical information covered by the Health Insurance
              Portability and Accountability Act of 1996 (HIPAA) and the
              California Confidentiality of Medical Information Act (CMIA) or
              clinical trial data
            </li>
            <li style={styles.listItem}>
              Personal Information covered by certain sector-specific privacy
              laws, including the Fair Credit Reporting Act (FRCA), the
              Gramm-Leach-Bliley Act (GLBA) or California Financial Information
              Privacy Act (FIPA), and the Driver's Privacy Protection Act of
              1994
            </li>
          </ul>
        </li>
      </ul>
      <h3 style={styles.subSubHeader}>Sources of Personal Information</h3>
      <p style={styles.paragraph}>
        We obtain the categories of personal information listed above from the
        following categories of sources:
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          <strong style={styles.strong}>Directly from You</strong>. For example,
          from the forms You complete on our Service, preferences You express or
          provide through our Service, or from Your purchases on our Service.
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>Indirectly from You</strong>. For
          example, from observing Your activity on our Service.
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>Automatically from You</strong>. For
          example, through cookies We or our Service Providers set on Your
          Device as You navigate through our Service.
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>From Service Providers</strong>. For
          example, third-party vendors to monitor and analyze the use of our
          Service, third-party vendors to deliver targeted advertising to You,
          third-party vendors for payment processing, or other third-party
          vendors that We use to provide the Service to You.
        </li>
      </ul>
      <h3 style={styles.subSubHeader}>Use of Personal Information</h3>
      <p style={styles.paragraph}>
        We may use or disclose personal information We collect for
        &quot;business purposes&quot; or &quot;commercial purposes&quot; (as
        defined under the CCPA/CPRA), which may include the following examples:
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          To operate our Service and provide You with Our Service.
        </li>
        <li style={styles.listItem}>
          To provide You with support and to respond to Your inquiries,
          including to investigate and address Your concerns and monitor and
          improve our Service.
        </li>
        <li style={styles.listItem}>
          To fulfill or meet the reason You provided the information. For
          example, if You share Your contact information to ask a question about
          our Service, We will use that personal information to respond to Your
          inquiry. If You provide Your personal information to purchase a
          product or service, We will use that information to process Your
          payment and facilitate delivery.
        </li>
        <li style={styles.listItem}>
          To respond to law enforcement requests and as required by applicable
          law, court order, or governmental regulations.
        </li>
        <li style={styles.listItem}>
          As described to You when collecting Your personal information or as
          otherwise set forth in the CCPA/CPRA.
        </li>
        <li style={styles.listItem}>
          For internal administrative and auditing purposes.
        </li>
        <li style={styles.listItem}>
          To detect security incidents and protect against malicious, deceptive,
          fraudulent or illegal activity, including, when necessary, to
          prosecute those responsible for such activities.
        </li>
        <li style={styles.listItem}>Other one-time uses.</li>
      </ul>
      <p style={styles.paragraph}>
        Please note that the examples provided above are illustrative and not
        intended to be exhaustive. For more details on how we use this
        information, please refer to the &quot;Use of Your Personal Data&quot;
        section.
      </p>
      <p style={styles.paragraph}>
        If We decide to collect additional categories of personal information or
        use the personal information We collected for materially different,
        unrelated, or incompatible purposes, We will update this Privacy Policy.
      </p>
      <h3 style={styles.subSubHeader}>Disclosure of Personal Information</h3>
      <p style={styles.paragraph}>
        We may use or disclose and may have used or disclosed in the last twelve
        (12) months the following categories of personal information for
        business or commercial purposes:
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>Category A: Identifiers</li>
        <li style={styles.listItem}>
          Category B: Personal information categories listed in the California
          Customer Records statute (Cal. Civ. Code § 1798.80(e))
        </li>
        <li style={styles.listItem}>Category D: Commercial information</li>
        <li style={styles.listItem}>
          Category F: Internet or other similar network activity
        </li>
      </ul>
      <p style={styles.paragraph}>
        Please note that the categories listed above are those defined in the
        CCPA/CPRA. This does not mean that all examples of that category of
        personal information were in fact disclosed, but reflects our good faith
        belief to the best of our knowledge that some of that information from
        the applicable category may be and may have been disclosed.
      </p>
      <p style={styles.paragraph}>
        When We disclose personal information for a business purpose or a
        commercial purpose, We enter a contract that describes the purpose and
        requires the recipient to both keep that personal information
        confidential and not use it for any purpose except performing the
        contract.
      </p>
      <h3 style={styles.subSubHeader}>Share of Personal Information</h3>
      <p style={styles.paragraph}>
        We may share, and have shared in the last twelve (12) months, Your
        personal information identified in the above categories with the
        following categories of third parties:
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>Service Providers</li>
        <li style={styles.listItem}>Payment processors</li>
        <li style={styles.listItem}>Our affiliates</li>
        <li style={styles.listItem}>Our business partners</li>
        <li style={styles.listItem}>
          Third party vendors to whom You or Your agents authorize Us to
          disclose Your personal information in connection with products or
          services We provide to You
        </li>
      </ul>
      <h3 style={styles.subSubHeader}>Sale of Personal Information</h3>
      <p style={styles.paragraph}>
        As defined in the CCPA/CPRA, &quot;sell&quot; and &quot;sale&quot; mean
        selling, renting, releasing, disclosing, disseminating, making
        available, transferring, or otherwise communicating orally, in writing,
        or by electronic or other means, a Consumer's personal information by
        the Business to a third party for valuable consideration. This means
        that We may have received some kind of benefit in return for sharing
        personal information, but not necessarily a monetary benefit.
      </p>
      <p style={styles.paragraph}>
        We do not sell personal information as the term sell is commonly
        understood. We do allow Service Providers to use Your personal
        information for the business purposes described in Our Privacy Policy,
        for activities such as advertising, marketing, and analytics, and these
        may be deemed a sale under CCPA/CPRA.
      </p>
      <p style={styles.paragraph}>
        We may sell and may have sold in the last twelve (12) months the
        following categories of personal information:
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>Category A: Identifiers</li>
        <li style={styles.listItem}>
          Category B: Personal information categories listed in the California
          Customer Records statute (Cal. Civ. Code § 1798.80(e))
        </li>
        <li style={styles.listItem}>Category D: Commercial information</li>
        <li style={styles.listItem}>
          Category F: Internet or other similar network activity
        </li>
      </ul>
      <p style={styles.paragraph}>
        Please note that the categories listed above are those defined in the
        CCPA/CPRA. This does not mean that all examples of that category of
        personal information were in fact sold, but reflects our good faith
        belief to the best of Our knowledge that some of that information from
        the applicable category may be and may have been shared for value in
        return.
      </p>
      <h3 style={styles.subSubHeader}>
        Sale of Personal Information of Minors Under 16 Years of Age
      </h3>
      <p style={styles.paragraph}>
        We do not knowingly collect personal information from minors under the
        age of 16 through our Service, although certain third party websites
        that we link to may do so. These third-party websites have their own
        terms of use and privacy policies and We encourage parents and legal
        guardians to monitor their children's Internet usage and instruct their
        children to never provide information on other websites without their
        permission.
      </p>
      <p style={styles.paragraph}>
        We do not sell the personal information of Consumers We actually know
        are less than 16 years of age, unless We receive affirmative
        authorization (the &quot;right to opt-in&quot;) from either the Consumer
        who is between 13 and 16 years of age, or the parent or guardian of a
        Consumer less than 13 years of age. Consumers who opt-in to the sale of
        personal information may opt-out of future sales at any time. To
        exercise the right to opt-out, You (or Your authorized representative)
        may submit a request to Us by contacting Us.
      </p>
      <p style={styles.paragraph}>
        If You have reason to believe that a child under the age of 13 (or 16)
        has provided Us with personal information, please contact Us with
        sufficient detail to enable Us to delete that information.
      </p>
      <h3 style={styles.subSubHeader}>Your Rights under the CCPA/CPRA</h3>
      <p style={styles.paragraph}>
        The CCPA/CPRA provides California residents with specific rights
        regarding their personal information. If You are a resident of
        California, You have the following rights:
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          <strong style={styles.strong}>The right to notice.</strong> You have
          the right to be notified which categories of Personal Data are being
          collected and the purposes for which the Personal Data is being used.
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>The right to know/access.</strong> Under
          CCPA/CPRA, You have the right to request that We disclose information
          to You about Our collection, use, sale, disclosure for business
          purposes and share of personal information. Once We receive and
          confirm Your request, We will disclose to You:
          <ul style={styles.list}>
            <li style={styles.listItem}>
              The categories of personal information We collected about You
            </li>
            <li style={styles.listItem}>
              The categories of sources for the personal information We
              collected about You
            </li>
            <li style={styles.listItem}>
              Our business or commercial purposes for collecting or selling that
              personal information
            </li>
            <li style={styles.listItem}>
              The categories of third parties with whom We share that personal
              information
            </li>
            <li style={styles.listItem}>
              The specific pieces of personal information We collected about You
            </li>
            <li style={styles.listItem}>
              If we sold Your personal information or disclosed Your personal
              information for a business purpose, We will disclose to You:
              <ul style={styles.list}>
                <li style={styles.listItem}>
                  The categories of personal information categories sold
                </li>
                <li style={styles.listItem}>
                  The categories of personal information categories disclosed
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>
            The right to say no to the sale or sharing of Personal Data
            (opt-out).
          </strong>{" "}
          You have the right to direct Us to not sell Your personal information.
          To submit an opt-out request, please see the &quot;Do Not Sell My
          Personal Information&quot; section or contact Us.
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>
            The right to correct Personal Data.
          </strong>{" "}
          You have the right to correct or rectify any inaccurate personal
          information about You that We collected. Once We receive and confirm
          Your request, We will use commercially reasonable efforts to correct
          (and direct our Service Providers to correct) Your personal
          information, unless an exception applies.
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>
            The right to limit use and disclosure of sensitive Personal Data.
          </strong>{" "}
          You have the right to request to limit the use or disclosure of
          certain sensitive personal information We collected about You, unless
          an exception applies. To submit, please see the &quot;Limit the Use or
          Disclosure of My Sensitive Personal Information&quot; section or
          contact Us.
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>
            The right to delete Personal Data.
          </strong>{" "}
          You have the right to request the deletion of Your Personal Data under
          certain circumstances, subject to certain exceptions. Once We receive
          and confirm Your request, We will delete (and direct Our Service
          Providers to delete) Your personal information from our records,
          unless an exception applies. We may deny Your deletion request if
          retaining the information is necessary for Us or Our Service Providers
          to:
          <ul style={styles.list}>
            <li style={styles.listItem}>
              Complete the transaction for which We collected the personal
              information, provide a good or service that You requested, take
              actions reasonably anticipated within the context of our ongoing
              business relationship with You, or otherwise perform our contract
              with You.
            </li>
            <li style={styles.listItem}>
              Detect security incidents, protect against malicious, deceptive,
              fraudulent, or illegal activity, or prosecute those responsible
              for such activities.
            </li>
            <li style={styles.listItem}>
              Debug products to identify and repair errors that impair existing
              intended functionality.
            </li>
            <li style={styles.listItem}>
              Exercise free speech, ensure the right of another consumer to
              exercise their free speech rights, or exercise another right
              provided for by law.
            </li>
            <li style={styles.listItem}>
              Comply with the California Electronic Communications Privacy Act
              (Cal. Penal Code § 1546 et. seq.).
            </li>
            <li style={styles.listItem}>
              Engage in public or peer-reviewed scientific, historical, or
              statistical research in the public interest that adheres to all
              other applicable ethics and privacy laws, when the information's
              deletion may likely render impossible or seriously impair the
              research's achievement, if You previously provided informed
              consent.
            </li>
            <li style={styles.listItem}>
              Enable solely internal uses that are reasonably aligned with
              consumer expectations based on Your relationship with Us.
            </li>
            <li style={styles.listItem}>Comply with a legal obligation.</li>
            <li style={styles.listItem}>
              Make other internal and lawful uses of that information that are
              compatible with the context in which You provided it.
            </li>
          </ul>
        </li>
        <li style={styles.listItem}>
          <strong style={styles.strong}>
            The right not to be discriminated against.
          </strong>{" "}
          You have the right not to be discriminated against for exercising any
          of Your consumer's rights, including by:
          <ul style={styles.list}>
            <li style={styles.listItem}>Denying goods or services to You</li>
            <li style={styles.listItem}>
              Charging different prices or rates for goods or services,
              including the use of discounts or other benefits or imposing
              penalties
            </li>
            <li style={styles.listItem}>
              Providing a different level or quality of goods or services to You
            </li>
            <li style={styles.listItem}>
              Suggesting that You will receive a different price or rate for
              goods or services or a different level or quality of goods or
              services
            </li>
          </ul>
        </li>
      </ul>
      <h3 style={styles.subSubHeader}>
        Exercising Your CCPA/CPRA Data Protection Rights
      </h3>
      <p style={styles.paragraph}>
        Please see the &quot;Do Not Sell My Personal Information&quot; section
        and &quot;Limit the Use or Disclosure of My Sensitive Personal
        Information&quot; section for more information on how to opt out and
        limit the use of sensitive information collected.
      </p>
      <p style={styles.paragraph}>
        Additionally, in order to exercise any of Your rights under the
        CCPA/CPRA, and if You are a California resident, You can contact Us:
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>By email: service@trainify.app</p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            By visiting this page on our website:{" "}
            <a
              style={styles.link}
              href="https://trainify.app/"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              https://trainify.app/
            </a>
          </p>
        </li>
      </ul>
      <p style={styles.paragraph}>
        Only You, or a person registered with the California Secretary of State
        that You authorize to act on Your behalf, may make a verifiable request
        related to Your personal information.
      </p>
      <p style={styles.paragraph}>Your request to Us must:</p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          Provide sufficient information that allows Us to reasonably verify You
          are the person about whom We collected personal information or an
          authorized representative
        </li>
        <li style={styles.listItem}>
          Describe Your request with sufficient detail that allows Us to
          properly understand, evaluate, and respond to it
        </li>
      </ul>
      <p style={styles.paragraph}>
        We cannot respond to Your request or provide You with the required
        information if We cannot:
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          Verify Your identity or authority to make the request
        </li>
        <li style={styles.listItem}>
          And confirm that the personal information relates to You
        </li>
      </ul>
      <p style={styles.paragraph}>
        We will disclose and deliver the required information free of charge
        within 45 days of receiving Your verifiable request. The time period to
        provide the required information may be extended once by an additional
        45 days when reasonably necessary and with prior notice.
      </p>
      <p style={styles.paragraph}>
        Any disclosures We provide will only cover the 12-month period preceding
        the verifiable request's receipt.
      </p>
      <p style={styles.paragraph}>
        For data portability requests, We will select a format to provide Your
        personal information that is readily usable and should allow You to
        transmit the information from one entity to another entity without
        hindrance.
      </p>
      <h3 style={styles.subSubHeader}>Do Not Sell My Personal Information</h3>
      <p style={styles.paragraph}>
        As defined in the CCPA/CPRA, &quot;sell&quot; and &quot;sale&quot; mean
        selling, renting, releasing, disclosing, disseminating, making
        available, transferring, or otherwise communicating orally, in writing,
        or by electronic or other means, a Consumer's personal information by
        the Business to a third party for valuable consideration. This means
        that We may have received some kind of benefit in return for sharing
        personal information, but not necessarily a monetary benefit.
      </p>
      <p style={styles.paragraph}>
        We do not sell personal information as the term sell is commonly
        understood. We do allow Service Providers to use Your personal
        information for the business purposes described in Our Privacy Policy,
        for activities such as advertising, marketing, and analytics, and these
        may be deemed a sale under CCPA/CPRA.
      </p>
      <p style={styles.paragraph}>
        You have the right to opt-out of the sale of Your personal information.
        Once We receive and confirm a verifiable consumer request from You, we
        will stop selling Your personal information. To exercise Your right to
        opt-out, please contact Us.
      </p>
      <p style={styles.paragraph}>
        The Service Providers we partner with (for example, our analytics or
        advertising partners) may use technology on the Service that sells
        personal information as defined by the CCPA/CPRA law. If you wish to opt
        out of the use of Your personal information for interest-based
        advertising purposes and these potential sales as defined under
        CCPA/CPRA law, you may do so by following the instructions below.
      </p>
      <p style={styles.paragraph}>
        Please note that any opt out is specific to the browser You use. You may
        need to opt out on every browser that You use.
      </p>
      <h4 style={styles.h4}>Website</h4>
      <p style={styles.paragraph}>
        If applicable, click &quot;Privacy Preferences&quot;, &quot;Update
        Privacy Preferences&quot; or &quot;Do Not Sell My Personal
        Information&quot; buttons listed on the Service to review Your privacy
        preferences and opt out of cookies and other technologies that We may
        use. Please note that You will need to opt out from each browser that
        You use to access the Service.
      </p>
      <p style={styles.paragraph}>
        Additionally, You can opt out of receiving ads that are personalized as
        served by our Service Providers by following our instructions presented
        on the Service:
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          The NAI's opt-out platform:{" "}
          <a
            style={styles.link}
            href="http://www.networkadvertising.org/choices/"
            rel="external nofollow noopener noreferrer"
            target="_blank"
          >
            http://www.networkadvertising.org/choices/
          </a>
        </li>
        <li style={styles.listItem}>
          The EDAA's opt-out platform{" "}
          <a
            style={styles.link}
            href="http://www.youronlinechoices.com/"
            rel="external nofollow noopener noreferrer"
            target="_blank"
          >
            http://www.youronlinechoices.com/
          </a>
        </li>
        <li style={styles.listItem}>
          The DAA's opt-out platform:{" "}
          <a
            style={styles.link}
            href="http://optout.aboutads.info/?c=2&amp;lang=EN"
            rel="external nofollow noopener noreferrer"
            target="_blank"
          >
            http://optout.aboutads.info/?c=2&amp;lang=EN
          </a>
        </li>
      </ul>
      <p style={styles.paragraph}>
        The opt out will place a cookie on Your computer that is unique to the
        browser You use to opt out. If you change browsers or delete the cookies
        saved by Your browser, You will need to opt out again.
      </p>
      <h4 style={styles.h4}>Mobile Devices</h4>
      <p style={styles.paragraph}>
        Your mobile device may give You the ability to opt out of the use of
        information about the apps You use in order to serve You ads that are
        targeted to Your interests:
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          &quot;Opt out of Interest-Based Ads&quot; or &quot;Opt out of Ads
          Personalization&quot; on Android devices
        </li>
        <li style={styles.listItem}>
          &quot;Limit Ad Tracking&quot; on iOS devices
        </li>
      </ul>
      <p style={styles.paragraph}>
        You can also stop the collection of location information from Your
        mobile device by changing the preferences on Your mobile device.
      </p>
      <h3 style={styles.subSubHeader}>
        Limit the Use or Disclosure of My Sensitive Personal Information
      </h3>
      <p style={styles.paragraph}>
        If You are a California resident, You have the right to limit the use
        and disclosure of Your sensitive personal information to that use which
        is necessary to perform the services or provide the goods reasonably
        expected by an average consumer who requests such services or goods.
      </p>
      <p style={styles.paragraph}>
        We collect, use and disclose sensitive personal information in ways that
        are necessary to provide the Service. For more information on how We use
        Your personal information, please see the &quot;Use of Your Personal
        Data&quot; section or contact us.
      </p>
      <h2 style={styles.subHeader}>
        &quot;Do Not Track&quot; Policy as Required by California Online Privacy
        Protection Act (CalOPPA)
      </h2>
      <p style={styles.paragraph}>
        Our Service does not respond to Do Not Track signals.
      </p>
      <p style={styles.paragraph}>
        However, some third party websites do keep track of Your browsing
        activities. If You are visiting such websites, You can set Your
        preferences in Your web browser to inform websites that You do not want
        to be tracked. You can enable or disable DNT by visiting the preferences
        or settings page of Your web browser.
      </p>
      <h2 style={styles.subHeader}>
        Your California Privacy Rights (California's Shine the Light law)
      </h2>
      <p style={styles.paragraph}>
        Under California Civil Code Section 1798 (California's Shine the Light
        law), California residents with an established business relationship
        with us can request information once a year about sharing their Personal
        Data with third parties for the third parties' direct marketing
        purposes.
      </p>
      <p style={styles.paragraph}>
        If you'd like to request more information under the California Shine the
        Light law, and if You are a California resident, You can contact Us
        using the contact information provided below.
      </p>
      <h2 style={styles.subHeader}>
        California Privacy Rights for Minor Users (California Business and
        Professions Code Section 22581)
      </h2>
      <p style={styles.paragraph}>
        California Business and Professions Code Section 22581 allows California
        residents under the age of 18 who are registered users of online sites,
        services or applications to request and obtain removal of content or
        information they have publicly posted.
      </p>
      <p style={styles.paragraph}>
        To request removal of such data, and if You are a California resident,
        You can contact Us using the contact information provided below, and
        include the email address associated with Your account.
      </p>
      <p style={styles.paragraph}>
        Be aware that Your request does not guarantee complete or comprehensive
        removal of content or information posted online and that the law may not
        permit or require removal in certain circumstances.
      </p>
      <h2 style={styles.subHeader}>Children's Privacy</h2>
      <p style={styles.paragraph}>
        Our Service does not address anyone under the age of 13. We do not
        knowingly collect personally identifiable information from anyone under
        the age of 13. If You are a parent or guardian and You are aware that
        Your child has provided Us with Personal Data, please contact Us. If We
        become aware that We have collected Personal Data from anyone under the
        age of 13 without verification of parental consent, We take steps to
        remove that information from Our servers.
      </p>
      <p style={styles.paragraph}>
        If We need to rely on consent as a legal basis for processing Your
        information and Your country requires consent from a parent, We may
        require Your parent's consent before We collect and use that
        information.
      </p>
      <h2 style={styles.subHeader}>Links to Other Websites</h2>
      <p style={styles.paragraph}>
        Our Service may contain links to other websites that are not operated by
        Us. If You click on a third party link, You will be directed to that
        third party's site. We strongly advise You to review the Privacy Policy
        of every site You visit.
      </p>
      <p style={styles.paragraph}>
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </p>
      <h2 style={styles.subHeader}>Changes to this Privacy Policy</h2>
      <p style={styles.paragraph}>
        We may update Our Privacy Policy from time to time. We will notify You
        of any changes by posting the new Privacy Policy on this page.
      </p>
      <p style={styles.paragraph}>
        We will let You know via email and/or a prominent notice on Our Service,
        prior to the change becoming effective and update the &quot;Last
        updated&quot; date at the top of this Privacy Policy.
      </p>
      <p style={styles.paragraph}>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </p>
      <h2 style={styles.subHeader}>Contact Us</h2>
      <p style={styles.paragraph}>
        If you have any questions about this Privacy Policy, You can contact us:
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>By email: service@trainify.app</p>
        </li>
        <li style={styles.listItem}>
          <p style={styles.paragraph}>
            By visiting this page on our website:{" "}
            <a
              style={styles.link}
              href="https://trainify.app/"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              https://trainify.app/
            </a>
          </p>
        </li>
      </ul>
    </section>
  );
}

const styles = {
  container: {
    padding: "40px",
    backgroundColor: "#222222",
  },
  header: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "10px",
    marginTop: "20px",
    color: "#fff",
  },
  date: {
    fontSize: "14px",
    marginBottom: "10px",
    color: "#fff",
  },
  paragraph: {
    fontSize: "16px",
    marginBottom: "10px",
    color: "#fff",
  },
  subHeader: {
    fontSize: "20px",
    fontWeight: "bold",
    marginTop: "20px",
    marginBottom: "10px",
    color: "#fff",
  },
  subSubHeader: {
    fontSize: "18px",
    fontWeight: "bold",
    marginTop: "15px",
    marginBottom: "5px",
    color: "#fff",
  },
  h4: {
    fontSize: "16px",
    fontWeight: "bold",
    marginTop: "13px",
    marginBottom: "3px",
    color: "#fff",
  },
  list: {
    marginLeft: "10px",
    color: "#fff",
  },
  listItem: {
    fontSize: "16px",
    marginBottom: "5px",
    color: "#fff",
  },
  strong: {
    fontWeight: "bold",
    color: "#fff",
  },
  link: {
    color: "blue",
    textDecoration: "underline",
  },
};
